import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
    const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        padding: 3,
      }}
    >
      <Typography variant="h3" sx={{ marginBottom: 2 }}>
        404 - Page Not Found
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 4 }}>
        The page you&apos;re looking for doesn&apos;t exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        sx={{ textDecoration: 'none' }}
      >
        Go Back to Home
      </Button>
    </Box>
  );
};

export default NotFound;
