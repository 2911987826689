import React from 'react';
import { useTheme, Drawer, Box, Button, IconButton, Typography, Divider } from '@mui/material';
import { Brightness7, Brightness4, Close as CloseIcon } from '@mui/icons-material';
import logo from '../assets/logo.svg';
import PropTypes from 'prop-types';

const Sidebar = ({ open, toggleDrawer }) => {
  const theme = useTheme();

  return (
    <Drawer 
    open={open} 
    onClose={toggleDrawer} 
    anchor="right"
    sx={{
      '& .MuiDrawer-paper': {
        width: 280, // Sidebar width
        height: '100vh', // Full height of the screen
        backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent background
        backdropFilter: 'blur(10px)', // Blur effect for the background
        boxShadow: 'none', // Optional: remove box shadow for a cleaner look
        borderRadius: 2, // Rounded corners for the drawer
      },
    }}>
      <Box
        sx={{
          width: 280, // Wider sidebar for a spacious feel
          display: 'flex',
          flexDirection: 'column',
          padding: 3,
          backgroundColor: theme.palette.background.paper, // Lighter background color for contrast
          color: theme.palette.text.primary,
          boxShadow: 3, // Adding some shadow for a depth effect
          borderRadius: 2, // Rounded corners
          position: 'relative',
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={toggleDrawer}
          sx={{
            position: 'absolute',
            top: 15,
            right: 15,
            color: theme.palette.text.primary,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Logo and Typography */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
          <img src={logo} alt="Logo" style={{ width: 60, height: 60, marginRight: 16 }} />
          <Typography
            variant="h5" // Larger, more prominent typography
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 600, // Slightly bolder font
              letterSpacing: 1.2, // Added letter-spacing for better readability
            }}
          >
            asmuammal
          </Typography>
        </Box>

        <Divider sx={{ marginBottom: 3, borderColor: theme.palette.divider }} />

        {/* Menu items aligned to the left */}
        <Button
          className="sidebar-button"
          href="/"
          fullWidth
          sx={{
            marginBottom: 3,
            textAlign: 'left',
            justifyContent: 'flex-start',
            color: theme.palette.text.primary,
            fontSize: '1.25rem', // Adjusted for better readability
            fontWeight: 500, // Moderate font weight for balance
            textTransform: 'none',
            paddingLeft: 2, // Added padding for a more comfortable spacing
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            borderRadius: 1, // Rounded corners for buttons
          }}
        >
          Home
        </Button>

        {/* You can add more buttons or links here */}
        <Button
          className="sidebar-button"
          href="/about"
          fullWidth
          sx={{
            marginBottom: 3,
            textAlign: 'left',
            justifyContent: 'flex-start',
            color: theme.palette.text.primary,
            fontSize: '1.25rem',
            fontWeight: 500,
            textTransform: 'none',
            paddingLeft: 2,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            borderRadius: 1,
          }}
        >
          About
        </Button>

        <Button
          className="sidebar-button"
          href="/services"
          fullWidth
          sx={{
            marginBottom: 3,
            textAlign: 'left',
            justifyContent: 'flex-start',
            color: theme.palette.text.primary,
            fontSize: '1.25rem',
            fontWeight: 500,
            textTransform: 'none',
            paddingLeft: 2,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            borderRadius: 1,
          }}
        >
          Services
        </Button>

        <Button
          className="sidebar-button"
          href="/contact"
          fullWidth
          sx={{
            marginBottom: 3,
            textAlign: 'left',
            justifyContent: 'flex-start',
            color: theme.palette.text.primary,
            fontSize: '1.25rem',
            fontWeight: 500,
            textTransform: 'none',
            paddingLeft: 2,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            borderRadius: 1,
          }}
        >
          Contact
        </Button>
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default Sidebar;
